import { isPlatformBrowser } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID, TransferState, makeStateKey } from '@angular/core';
import * as fromRoot from '@app/reducers';
import { getLanguageWithFallback } from '@app/shared/helpers/language';
import { computePendingRolesAndPoliciesUpdates } from '@app/shared/helpers/permissions';
import { resolveCountries } from '@app/shared/helpers/vat';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { loadStripe } from '@stripe/stripe-js/pure';
import { DeviceDetectorService } from 'ngx-device-detector';
import { defer, forkJoin, from, of } from 'rxjs';
import { catchError, concatMap, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { Constant } from 'src/constant';
import { environment } from 'src/environments/environment';
import { EnvironmentActions } from '../actions';
import { CookiesService } from '../services/cookies.service';
import { IamService } from '../services/iam.service';
import { RedirectionService } from '../services/redirection.service';
import { ScitylanaService } from '../services/scitylana.service';
import { TokenService } from '../services/token.service';

import { Router } from '@angular/router';
import { setConfigHosts, setRegion } from '@app/shared/helpers/config';
import { genericRetry } from '@app/shared/operators/genericRetry';
import { Language } from '@app/shared/types/Language';
import { Region } from '@smash-sdk/core';
import { Discovery } from '@smash-sdk/discovery/ejs/02-2023/discovery';
import ListPublicServicesError from '@smash-sdk/discovery/ejs/02-2023/types/ListPublicServices/ListPublicServicesError';
import { Vat } from '@smash-sdk/vat/ejs/10-2019/vat';
import { GoogleTagManagerService } from '../lib/google-tag-manager/angular-google-tag-manager.service';
import { hostnameIsFromMainDomain } from '@app/shared/helpers/location';

export const DISCOVERY_SERVICES_KEY = makeStateKey('discovery-services');
export const VAT_COUNTRIES_KEY = makeStateKey('vat-countries');

@Injectable()
export class EnvironmentEffects {

  constructor(
    private actions$: Actions,
    private tokenService: TokenService,
    private translate: TranslateService,
    private store: Store<fromRoot.State>,
    private deviceService: DeviceDetectorService,
    private iamService: IamService,
    private cookiesService: CookiesService,
    private scitylanaService: ScitylanaService,
    private router: Router,
    private redirectionService: RedirectionService,
    private state: TransferState,
    private googleTagManagerService: GoogleTagManagerService,
    @Inject(PLATFORM_ID) private platformId: object
  ) {

  }
  loadDeviceInfos$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EnvironmentActions.loadDevice),
      switchMap(() => {
        const device = {
          deviceInfo: this.deviceService.getDeviceInfo(),
          isMobile: this.deviceService.isMobile(),
          isDesktop: this.deviceService.isDesktop(),
          isTablet: this.deviceService.isTablet()
        };
        return [EnvironmentActions.loadDeviceSuccess({ device })];
      })
    ));

  // Action to retrieve Services from current Domain region
  loadServicesFromDomain$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EnvironmentActions.loadServicesFromDomain),
      withLatestFrom(
        this.store.select(fromRoot.getCurrentDomain),
      ),
      switchMap(([_, domain]) => {
        if (domain?.region) {
          const discoverySdk = new Discovery({ region: domain.region as Region });
          return defer(() => discoverySdk.listPublicServices()).pipe(
            genericRetry({
              retryConfig: [
                ...Constant.NetworkErrorsRetryConfiguration,
                { error: ListPublicServicesError.InternalServerError, maxRetry: 10, exponentialBackoff: true },
                { error: ListPublicServicesError.BadGatewayError, maxRetry: 10, exponentialBackoff: true },
                { error: ListPublicServicesError.GatewayTimeoutError, maxRetry: 10, exponentialBackoff: true },
              ]
            }),
            concatMap(({ country, countryCode }) => {
              return [
                EnvironmentActions.loadServicesSuccess({ region: domain.region, country, countryCode }),
              ];
            }),
            catchError((error: HttpErrorResponse) => {
              this.router.navigateByUrl(Constant.redirection.serviceUnavailable);
              throw error; // important to throw error so it can bubble up
            }));
        } else {
          // SSR Loading default service
          const servicesFromState: any = this.state.get(DISCOVERY_SERVICES_KEY, null);
          if (servicesFromState) {
            // to do handle transfer status
            return of(EnvironmentActions.loadServicesSuccess({ ...servicesFromState }));
          } else {
            const discoverySdk = new Discovery({ host: environment.discovery });
            return defer(() => discoverySdk.listPublicServices()).pipe(
              genericRetry({
                retryConfig: [
                  ...Constant.NetworkErrorsRetryConfiguration,
                  { error: ListPublicServicesError.InternalServerError, maxRetry: 10, exponentialBackoff: true },
                  { error: ListPublicServicesError.BadGatewayError, maxRetry: 10, exponentialBackoff: true },
                  { error: ListPublicServicesError.GatewayTimeoutError, maxRetry: 10, exponentialBackoff: true },
                ]
              }),
              concatMap(({ region, country, countryCode }) => {
                this.state.set(DISCOVERY_SERVICES_KEY, { region, country, countryCode } as any);
                return [
                  EnvironmentActions.loadServicesSuccess({ region, country, countryCode }),
                ];
              }),
              catchError((error: HttpErrorResponse) => {
                this.router.navigateByUrl(Constant.redirection.serviceUnavailable);
                throw error; // important to throw error so it can bubble up
              }));
          }

        }
      })
    ));
  // Action to retrieve services from token region
  loadServicesFromToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EnvironmentActions.loadServicesFromToken),
      withLatestFrom(
        this.store.select(fromRoot.getActiveIdentity),
      ),
      switchMap(([_, identity]) => {
        const { region } = identity;
        const discoverySdk = new Discovery({ region: region as Region });
        return defer(() => discoverySdk.listPublicServices()).pipe(
          genericRetry({
            retryConfig: [
              ...Constant.NetworkErrorsRetryConfiguration,
              { error: ListPublicServicesError.InternalServerError, maxRetry: 10, exponentialBackoff: true },
              { error: ListPublicServicesError.BadGatewayError, maxRetry: 10, exponentialBackoff: true },
              { error: ListPublicServicesError.GatewayTimeoutError, maxRetry: 10, exponentialBackoff: true },
            ]
          }),
          concatMap(({ country, countryCode }) => {
            return [EnvironmentActions.loadServicesSuccess({ region, country, countryCode })];
          }));
      })
    ));

  loadServicesFromRegion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EnvironmentActions.loadServicesFromRegion),
      switchMap((action) => {
        const { region } = action;
        const discoverySdk = new Discovery({ region: region as Region });
        return defer(() => discoverySdk.listPublicServices()).pipe(
          genericRetry({
            retryConfig: [
              ...Constant.NetworkErrorsRetryConfiguration,
              { error: ListPublicServicesError.InternalServerError, maxRetry: 10, exponentialBackoff: true },
              { error: ListPublicServicesError.BadGatewayError, maxRetry: 10, exponentialBackoff: true },
              { error: ListPublicServicesError.GatewayTimeoutError, maxRetry: 10, exponentialBackoff: true },
            ]
          }),
          concatMap(({ country, countryCode }) => {
            return [EnvironmentActions.loadServicesSuccess({ region, country, countryCode })];
          }));
      })
    ));

  loadServices$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EnvironmentActions.loadServices),
      withLatestFrom(this.store.pipe(select(fromRoot.getActiveIdentity))),
      map(([action, identity]: any) => {
        if (action.region) {
          return EnvironmentActions.loadServicesFromRegion({ region: action.region });
        } else if (!!identity?.username) {
          return EnvironmentActions.loadServicesFromToken();
        } else {
          return EnvironmentActions.loadServicesFromDomain();
        }
      })
    )
  );

  loadServicesSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EnvironmentActions.loadServicesSuccess),
      tap((action) => {
        setConfigHosts(environment.services);
        setRegion(action.region as Region);
        console.log('Region: ', action.region);
        if (isPlatformBrowser(this.platformId)) {
          this.googleTagManagerService.pushTag({
            smash_country_code: action.countryCode,
            smash_country: action.country,
            smash_region: action.region,
          });
        }
      })
    ), { dispatch: false }
  );


  selectLanguage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EnvironmentActions.SelectLanguage),
      switchMap(({ language }) => {
        this.cookiesService.setCookie('lang', language);
        return this.translate.use(language).pipe(map(() => {
          return EnvironmentActions.SelectLanguageSuccess({ language });
        }));
      })
    ),
  );

  setupLanguage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EnvironmentActions.SetupLanguage),
      withLatestFrom(
        this.store.pipe(select(fromRoot.getSelectedLanguage)),
      ),
      switchMap(([action, selectedLanguage]) => {
        // this is not executed anymore serverside. since Appcomponent is only bootstraped browserside
        if (selectedLanguage) {
          return this.translate.use(selectedLanguage).pipe(map(() => {
            return EnvironmentActions.SetupLanguageSuccess({ language: selectedLanguage });
          }));
        } else {
          const browserLang = this.translate.getBrowserLang();
          const targetLang: Language = getLanguageWithFallback(browserLang);
          this.cookiesService.setCookie('lang', targetLang);
          return this.translate.use(targetLang).pipe(map(() => {
            return EnvironmentActions.SetupLanguageSuccess({ language: targetLang });
          }));
        }
      })
    )
  );

  loadCountries$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EnvironmentActions.loadCountries),
      switchMap(() => {
        // SSR Loading default countries
        const countriesFromState: any = this.state.get(VAT_COUNTRIES_KEY, null);
        if (countriesFromState) {
          // to do handle transfer status
          return of(EnvironmentActions.loadCountriesSuccess({ ...countriesFromState }));
        } else {
          const vatSdk = new Vat();
          return from(vatSdk.listCountries()).pipe(
            map(({ countries }) => {
              const resolvedCountries = resolveCountries(countries);
              this.state.set(VAT_COUNTRIES_KEY, { countries: resolvedCountries } as any);
              return EnvironmentActions.loadCountriesSuccess({ countries: resolvedCountries });
            }),
            catchError((error: any) => of(EnvironmentActions.loadCountriesFailure({ error: error.message })))
          );
        }

      })
    )
  );

  followDomainRedirect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EnvironmentActions.FollowDomainRedirect),
      tap((action) => {
        const { protocol, port } = window.location;
        window.location.href = `${protocol}//${action.redirect}${port ? ':' + port : ''}`;
      })
    ),
    { dispatch: false }
  );

  redirectToSelectedDomain$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EnvironmentActions.redirectToSelectedDomain),
      withLatestFrom(
        this.store.pipe(select(fromRoot.getCurrentDomain)),
        this.store.pipe(select(fromRoot.getActiveIdentity)),
      ),
      tap(([action, currentDomain, identity]) => {
        const cookieLastActiveTeam = this.cookiesService.getCookie('lastActiveTeam');
        if (cookieLastActiveTeam?.team !== currentDomain?.team) {
          const { protocol, port } = window.location;
          const { account, id, language, refreshToken, region, token, username } = identity;

          try {
            const encodedIdentity = encodeURIComponent(btoa(JSON.stringify({ identity: { account, id, language, refreshToken, region, token, username } })));
            window.location.href = `${protocol}//${cookieLastActiveTeam.domain}${port ? ':' + port : ''}?auth=${encodedIdentity}`;
          } catch (error) {
            throw error;
          }
        } else {
          this.router.navigateByUrl('/');
        }
      })
    ),
    { dispatch: false }
  );

  redirectToAccountWithSelectedDomain$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EnvironmentActions.redirectToAccountWithSelectedDomain),
      withLatestFrom(
        this.store.pipe(select(fromRoot.getActiveTeam)),
      ),
      tap(([action, selectedTeam]) => {
        const { protocol, port } = window.location;
        window.location.href = `${protocol}//${selectedTeam.domain}${port ? ':' + port : ''}/account/team/${selectedTeam.id}/transfers/sent`;
      })
    ),
    { dispatch: false }
  );

  scanUpdates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EnvironmentActions.ScanUpdates),
      concatMap((action: any) => {
        return [ // add tasks here
          EnvironmentActions.GetRemotePoliciesAndRoles()
        ];
      })
    ));

  runUpdates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EnvironmentActions.RunUpdates),
      withLatestFrom(
        this.store.select(fromRoot.getPendingUpdates)
      ),
      concatMap(([action, Updates]: any) => {
        const actions: Action[] = Updates.map(updateAction => updateAction.action);
        if (actions.length) {
          return actions;
        }
        return [{ type: 'RunUpdates noop' }];
      })
    ));

  finalizeUpdates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EnvironmentActions.FinalizeUpdates),
      withLatestFrom(
        this.store.select(fromRoot.getPendingUpdates)
      ),
      concatMap(([action, Updates]: any) => {
        const actions: Action[] = Updates.map(updateAction => updateAction.action);
        if (!actions.length) {
          return [EnvironmentActions.RunUpdatesSuccess({ refresh: true })];
        }
        return [{ type: 'FinalizeUpdates noop' }];
      })
    ));

  runUpdatesSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EnvironmentActions.RunUpdatesSuccess),
      tap((action: any) => {
        if (action.refresh) {
          window.location.reload();
        }
      })
    ), { dispatch: false });

  getRemotePoliciesAndRoles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EnvironmentActions.GetRemotePoliciesAndRoles),
      concatMap((action) => {
        return forkJoin([this.iamService.listPolicies(), this.iamService.listHydratedRoles()]).pipe(
          mergeMap(([{ policies }, roles]: any) => {
            const filteredPolicies = policies.filter(policy => !policy?.name?.startsWith('Managed') && (!policy?.tags?.applicationID || policy?.tags?.applicationID === Constant.resourceTagValues['applicationID']));
            const filteredRoles = roles.filter(role => !role?.name?.startsWith('Managed') && (!role?.tags?.applicationID || role?.tags?.applicationID === Constant.resourceTagValues['applicationID']));
            return [EnvironmentActions.GetRemotePoliciesAndRolesSuccess({ remotePolicies: filteredPolicies, remoteRoles: filteredRoles })];
          })
        );
      }),
      catchError((error: any) => {
        return of(EnvironmentActions.GetRemotePoliciesAndRolesFailure({ error: error.message }))
      })
    ));

  getRemotePoliciesAndRolesSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EnvironmentActions.GetRemotePoliciesAndRolesSuccess),
      withLatestFrom(
        this.store.select(fromRoot.getActiveIdentity),
        this.store.select(fromRoot.getUserTeams),
      ),
      concatMap(([action, activeIdentity, teams]: any) => {
        const { remotePolicies, remoteRoles } = action;
        const account = this.tokenService.getAccountFromToken(activeIdentity.token.token);
        const updateObj: any = computePendingRolesAndPoliciesUpdates({ remotePolicies, remoteRoles }, { account, teams, region: activeIdentity.region });
        if (updateObj.status === 'need update') {
          updateObj.action = EnvironmentActions.ExecutePoliciesUpdate({ ...updateObj.tasks });
          return [EnvironmentActions.ComputePoliciesSuccess({ update: updateObj })];
        }

        return [{ type: 'ComputePolicies noop' }];
      })
    ));

  executePoliciesUpdate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EnvironmentActions.ExecutePoliciesUpdate),
      withLatestFrom(
        this.store.select(fromRoot.getPendingUpdates)
      ),
      concatMap(([action, pendingUpdates]: any) => {
        const { PoliciesToCreate, PoliciesToUpdate, RolesToDelete, RolesToCreate, RolesToUpdate, PoliciesToDelete } = action;
        return this.iamService.executePolicyRoleUpdate({ PoliciesToCreate, PoliciesToUpdate, RolesToDelete, RolesToCreate, RolesToUpdate, PoliciesToDelete }).pipe(
          concatMap((res: any) => {
            const updatesToRun = pendingUpdates.find(update => update.status === 'need update' && update.name === 'Update Policies & Roles');
            return [EnvironmentActions.ExecutePoliciesUpdateSuccess({ update: updatesToRun }), EnvironmentActions.FinalizeUpdates()];
          }));
      })
    ));

  acceptCGU$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EnvironmentActions.AcceptCGU),
      map(() => {
        const cgu = {
          cguLastConsentUpdated: new Date().toISOString(),
          cguLastVersion: environment.cgu.version,
          cguLastConsentValue: true,
        };
        this.cookiesService.setCookie('cgu', cgu);
        return EnvironmentActions.AcceptCGUSuccess({ cgu });
      })
    ));

  onBoarding$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EnvironmentActions.OnBoarding),
      withLatestFrom(
        this.store.select(fromRoot.getSelectedLanguage),
      ),
      tap(([action, language]) => {
        const { onBoardingState, redirectToPricing } = action;
        this.cookiesService.setCookie('onBoarding', onBoardingState);
        this.store.dispatch(EnvironmentActions.OnBoardingSuccess({ onBoardingState }));
        if (redirectToPricing) {
          this.redirectionService.redirectTo(redirectToPricing);
        }
      })
    ),
    { dispatch: false });

  loadCookiesConsentConfiguration$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EnvironmentActions.LoadCookiesConsentConfiguration),
      map(() => {
        const cookies = this.cookiesService.getAllCookies();
        const cookiesConsentSetByUser = cookies._sucs;

        if (!cookiesConsentSetByUser) {
          this.cookiesService.removeAllCookiesExceptSystemAndCloufront();
        }

        const cookiesConsentConfiguration = this.cookiesService.getCookiesConsentConfiguration();

        if (!cookiesConsentConfiguration.groups.social.enabled) {
          this.cookiesService.cleanCookiesConsentGroupAssociatedCookies('social');
        }
        
        if (!cookiesConsentConfiguration.groups.analytics.enabled) {
          this.cookiesService.cleanCookiesConsentGroupAssociatedCookies('analytics');
        }

        this.googleTagManagerService.pushTag({ event: Constant.eventGTM.CONSENT_UPDATE });
        return EnvironmentActions.LoadCookiesConsentConfigurationSuccess({ cookiesConsentConfiguration, cookiesConsentSetByUser });
      })
    ));

  acceptAllCookies$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EnvironmentActions.AcceptAllCookies),
      map(() => {
        this.googleTagManagerService.pushTag({ event: Constant.eventGTM.CONSENT_UPDATE });
        return EnvironmentActions.UpdateCookiesConsentConfiguration({
          cookiesConsentConfiguration: {
            groups: {
              analytics: { enabled: true },
              social: { enabled: true },
            }
          }
        });
      })
    ));

  denyAllCookies$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EnvironmentActions.DenyAllCookies),
      map(() => {
        this.googleTagManagerService.pushTag({ event: Constant.eventGTM.CONSENT_UPDATE });
        const isMainDomain = hostnameIsFromMainDomain(location.hostname);
        return EnvironmentActions.UpdateCookiesConsentConfiguration({
          cookiesConsentConfiguration: {
            groups: {
              analytics: { enabled: isMainDomain ? true : false },
              social: { enabled: false },
            }
          }
        });
      })
    ));


  updateCookiesConfiguration$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EnvironmentActions.UpdateCookiesConsentConfiguration),
      withLatestFrom(this.store.select(fromRoot.getCookiesConsentConfiguration)),
      map(([action, oldCookiesConsentConfiguration]) => {
        const { cookiesConsentConfiguration } = action;
        this.cookiesService.setCookie('userConsentSet', true);
        if (cookiesConsentConfiguration.groups.social.enabled) {
          this.cookiesService.acceptCookiesConsentGroup('social');
        } else {
          this.cookiesService.denyCookiesConsentGroup('social');
        }
        if (cookiesConsentConfiguration.groups.analytics.enabled) {
          this.cookiesService.acceptCookiesConsentGroup('analytics');
        } else {
          this.cookiesService.denyCookiesConsentGroup('analytics');
        }
        this.googleTagManagerService.pushTag({ event: Constant.eventGTM.CONSENT_UPDATE });
        return EnvironmentActions.UpdateCookiesConsentConfigurationSuccess({ cookiesConsentConfiguration, oldCookiesConsentConfiguration });
      })
    ));

  updateCookiesConfigurationSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EnvironmentActions.UpdateCookiesConsentConfigurationSuccess),
      tap((action) => {
        // If a cookie switch to 'enabled' = false, reload page in order to remove everything associated to this cookie
        const { oldCookiesConsentConfiguration, cookiesConsentConfiguration } = action;
        let newCookiesDisabled = false;
        Object.entries(oldCookiesConsentConfiguration.groups).forEach(([key, value]) => {
          if (value.enabled && !cookiesConsentConfiguration.groups[key].enabled) {
            newCookiesDisabled = true;
          }
        });
        if (newCookiesDisabled) {
          window.location.reload();
        }
      })
    ), { dispatch: false });

  loadStripe$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EnvironmentActions.LoadStripe),
      concatMap(() => {
        return from(loadStripe(environment.stripeKey)).pipe(map(() => EnvironmentActions.LoadStripeSuccess()));
      }),
      catchError(error => of(EnvironmentActions.LoadStripeFailure({ error })))
    ));

  createEventScitylana$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EnvironmentActions.CreateEventScitylana),
      concatMap((action) => {
        return this.scitylanaService.postTneveOnScitylana(action.event).pipe(map(() => {
          return EnvironmentActions.CreateEventScitylanaSuccess();
        }));
      }),
      catchError(error => of(EnvironmentActions.CreateEventScitylanaFailure({ error: error.message })))
    ));
}
