import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import moment from 'moment';
import { CookieOptions, CookieService } from 'ngx-cookie-service'; // does not support angular universal
import { extractDomainSuffixOrCNAME } from '@app/shared/helpers/location';
import { isPlatformBrowser } from '@angular/common';
export type CookieGroupName = 'social' | 'analytics';
export type CookieKey = 'lang' | 'lastActiveTeam' | 'userConsentSet' | 'ga4Analytics' | 'identity' | 'cgu' | 'fbPixelConsent' | 'linkedinConsent' | 'googleAdsConsent' | 'twitterConsent' | 'onBoarding' | 'SSOLoginOrigin' | 'lastSelectedCampaign';

interface AssociatedCookie {
  type: 'key' | 'regex';
  value: string
};

// Source: https://cookiedatabase.org
// These cookies are associated with the social networks and marketing tools we use. They are not set by us, but by the third-party services we use.

const facebookCookies: AssociatedCookie[] = [
  { type: 'key', value: '__fb_chat_plugin' },
  { type: 'key', value: '_js_datr' },
  { type: 'key', value: '_fbc' },
  { type: 'key', value: 'xs' },
  { type: 'key', value: 'wd' },
  { type: 'key', value: 'fr' },
  { type: 'key', value: 'act' },
  { type: 'key', value: '_fbp' },
  { type: 'key', value: 'datr' },
  { type: 'key', value: 'c_user' },
  { type: 'key', value: 'csm' },
  { type: 'key', value: 'sb' },
  { type: 'key', value: 'actppresence' },
  { type: 'regex', value: 'fbm(.+)' },
  { type: 'regex', value: '(.+)_fbm_' },
];

const linkedinCookies: AssociatedCookie[] = [
  { type: 'key', value: 'sdsc' },
  { type: 'key', value: 'lms_ads' },
  { type: 'key', value: 'lms_analytics' },
  { type: 'key', value: '_guid' },
  { type: 'key', value: '_guid' },
  { type: 'key', value: 'li_alerts' },
  { type: 'key', value: 'li_gc' },
  { type: 'key', value: 'AnalyticsSyncHistory' },
  { type: 'key', value: 'bcookie' },
  { type: 'key', value: 'li-oatml' },
  { type: 'key', value: 'BizographicsOptOut' },
  { type: 'key', value: 'li_sugr' },
  { type: 'key', value: 'UserMatchHistory' },
  { type: 'key', value: 'linkedin_oauth_' },
  { type: 'key', value: 'lidc' },
  { type: 'key', value: 'bscookie' },
  { type: 'key', value: 'X-LI-IDC' },
];

const googleAnalyticsCookies: AssociatedCookie[] = [
  { type: 'key', value: '_ga' },
  { type: 'regex', value: '_ga_(.+)' },
  { type: 'regex', value: '_gac_UA-(.+)' },
  { type: 'key', value: 'utm_source' },
  { type: 'key', value: 'utm_campaign' },
  { type: 'key', value: '__utmt' },
  { type: 'key', value: '__utm.gif' },
  { type: 'key', value: '__utmb' },
  { type: 'regex', value: '_gat_(.+)' },
  { type: 'regex', value: '_ga_(.+)' },
  { type: 'key', value: 'UTMD_' },
  { type: 'key', value: '__utmv' },
  { type: 'regex', value: '_gat_gtag_UA-(.+)' },
  { type: 'regex', value: '_dc_gtm_UA-(.+)' },
  { type: 'key', value: 'lsid' },
  { type: 'key', value: '_gat_single' },
  { type: 'regex', value: '_gat_UA-(.+)' },
  { type: 'regex', value: '(.+)_utm' },
  { type: 'key', value: '_gat' },
  { type: 'key', value: '_gid' },
  { type: 'key', value: '_gaexp' },
  { type: 'regex', value: '(.+)_gat_UA' },
  { type: 'key', value: '_utmb' },
  { type: 'key', value: '__utmc' },
  { type: 'key', value: '__utmz' },
  { type: 'key', value: '__utma' }
];

const googleAdsCookies: AssociatedCookie[] = [
  { type: 'key', value: 'google_pub_config' },
  { type: 'key', value: 'google_ama_config' },
  { type: 'key', value: 'google_ama_settings' },
  { type: 'key', value: 'google_adsense_settings' },
  { type: 'key', value: '_gcl_au' },
];

const twitterCookies: AssociatedCookie[] = [
  { type: 'key', value: 'local_storage_support_test' },
  { type: 'key', value: 'metrics_token' },
];

export interface CookieConsent {
  enabled: boolean;
}
export interface CookieGroup {
  enabled: boolean;
}

export interface CookiesConfiguration {
  groups: { [name in CookieGroupName]?: CookieGroup };
}

interface CookieConfiguration {
  key: string;
  type?: 'consent' | 'system';
  group?: CookieGroupName;
  expires?: number;
  scope?: Scope;
  associatedCookies?: Array<{ type: 'key' | 'regex'; value: string }>;
}

type Scope = 'self' | 'shared';

export const cookies: { [name in CookieKey]: CookieConfiguration } = {
  identity: { key: '_sid', type: 'system', expires: 2628000000, scope: 'shared' },
  lastActiveTeam: { key: '_stid', type: 'system', expires: 2628000000, scope: 'shared' },
  cgu: { key: '_scgu', type: 'system', expires: 2628000000, scope: 'shared' },
  userConsentSet: { key: '_sucs', type: 'system', expires: 15778800000, scope: 'self' },
  fbPixelConsent: { key: '_sfbpc', type: 'consent', group: 'social', expires: 15778800000, scope: 'self', associatedCookies: facebookCookies },
  googleAdsConsent: { key: '_sgaac', type: 'consent', group: 'social', expires: 15778800000, scope: 'self', associatedCookies: googleAdsCookies },
  linkedinConsent: { key: '_slkic', type: 'consent', group: 'social', expires: 15778800000, scope: 'self', associatedCookies: linkedinCookies },
  twitterConsent: { key: '_stc', type: 'consent', group: 'social', expires: 15778800000, scope: 'self', associatedCookies: twitterCookies },
  ga4Analytics: { key: '_sga4c', type: 'consent', group: 'analytics', expires: 15778800000, scope: 'self', associatedCookies: googleAnalyticsCookies },
  onBoarding: { key: '_sob', type: 'system', expires: 15778800000, scope: 'shared' },
  lang: { key: '_slang', type: 'system', expires: 15778800000, scope: 'shared' },
  SSOLoginOrigin: { key: '_ssolo', type: 'system', expires: 15778800000, scope: 'shared' },
  lastSelectedCampaign: { key: '_slsc', type: 'system', expires: 15778800000, scope: 'shared' },
};

@Injectable({
  providedIn: 'root'
})
export class CookiesService {

  constructor(
    private cookieService: CookieService,
    @Inject(PLATFORM_ID) private platformId: any) {
  }

  getCookie(key: CookieKey): any {
    if (this.cookieService.get(cookies[key].key)) {
      try {
        return JSON.parse(decodeURIComponent(this.cookieService.get(cookies[key].key)));
      } catch (error) {
        return null;
      }
    } else {
      return null;
    }
  }

  setCookie(key: CookieKey, object: any): void {
    if (isPlatformBrowser(this.platformId)) {
      const cookie = cookies[key];
      const options: CookieOptions = {
        path: '/',
        expires: cookie.expires ? moment().add(cookie.expires, 'ms').toDate() : cookie.expires,
      }
      if (cookie.scope === 'shared') {
        options.domain = extractDomainSuffixOrCNAME();
      }
      this.cookieService.set(
        cookie.key,
        JSON.stringify(object),
        options,
      );
    }
  }

  acceptCookiesConsentGroup(group: CookieGroupName): void {
    Object.entries(cookies).forEach(([key, value]) => {
      if (cookies[key].group === group && cookies[key].type === 'consent') {
        this.setCookie(key as CookieKey, true);
      }
    });
  }

  denyCookiesConsentGroup(group: CookieGroupName): void {
    Object.entries(cookies).forEach(([key]) => {
      if (cookies[key].group === group && cookies[key].type === 'consent') {
        this.setCookie(key as CookieKey, false);
        if (cookies[key].associatedCookies) {
          cookies[key].associatedCookies.forEach((cookieName) => {
            this.cookieService.delete(cookieName, '/', extractDomainSuffixOrCNAME(), false, 'Lax');
          });
        }
      }
    });
  }

  getAllCookies(): any {
    const allCookies = this.cookieService.getAll();
    return allCookies;
  }

  isCookieGroupEnabled(group: CookieGroupName): boolean {
    const cookiesFromGroup = Object.entries(cookies).filter(([key, value]) => value.group === group);
    return cookiesFromGroup.every(([key, value]) => this.getCookie(key as CookieKey) === true);
  }

  removeCookie(key: CookieKey): void {
    return this.cookieService.delete(cookies[key].key, '/', extractDomainSuffixOrCNAME(), false, 'Lax');
  }

  isCookieFromCloudFront(key: string): boolean {
    return key?.startsWith('CloudFront');
  }

  isCookieSystem(key: string): boolean {
    const cookieConfigurationFound = Object.entries(cookies).find(([_, cookieValue]) => cookieValue.key === key);
    return cookieConfigurationFound && cookieConfigurationFound[1].type === 'system';
  }

  removeAllCookiesExceptSystemAndCloufront(): void {
    const cookiesRetrieved = this.getAllCookies();
    Object.entries(cookiesRetrieved).forEach(([key]) => {
        if (!this.isCookieSystem(key) && !this.isCookieFromCloudFront(key)) {
          this.cookieService.delete(key, '/', extractDomainSuffixOrCNAME(), false, 'Lax');
        }
    });
  }

  cookieExists(key: CookieKey): boolean {
    return this.cookieService.check(cookies[key].key);
  }

  getCookiesConsentConfiguration(): CookiesConfiguration {
    const cookieConsentConfiguration: CookiesConfiguration = { groups: {} };
    Object.entries(cookies).forEach(([key, value]) => {
      if (value.group) {
        if (!cookieConsentConfiguration.groups[value.group]) {
          cookieConsentConfiguration.groups[value.group] = { enabled: true };
        }
        const cookieValue = this.getCookie(key as CookieKey);
        if (cookieValue === false || !cookieValue) {
          cookieConsentConfiguration.groups[value.group].enabled = false;
        }
      }
    });
    return cookieConsentConfiguration;
  }

  cleanCookiesConsentGroupAssociatedCookies(group: CookieGroupName): void {
    const cookiesRetrieved = this.getAllCookies();
    Object.entries(cookies).forEach(([key, value]) => {
      if (cookies[key].group === group && cookies[key].type === 'consent') {
        if (cookies[key].associatedCookies) {
          cookies[key].associatedCookies.forEach(({ type, value }) => {
            if (type === 'key' && cookiesRetrieved[value]) {
              this.cookieService.delete(value, '/', extractDomainSuffixOrCNAME(), false, 'Lax');
            }
            if (type === 'regex') {
              const regexp = new RegExp(value);
              Object.entries(cookiesRetrieved).forEach(([cookieName]) => {
                if (regexp.test(cookieName)) {
                  this.cookieService.delete(cookieName, '/', extractDomainSuffixOrCNAME(), false, 'Lax');
                }
              });
            }
          });
        }
      }
    });
  }
}
